import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Layout from '../../layouts/mdx';
export const _frontmatter = {
  "product": "signatures",
  "title": "Criipto Signatures",
  "subtitle": "Sign documents and PDFs with national eIDs and the PAdES standard"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Criipto Signatures allows you to sign any PDF document using all the national eIDs supported by Criipto Verify.`}</p>
    <p>{`Documents will be produced according to the `}<a parentName="p" {...{
        "href": "https://www.criipto.com/blog/pades-signature-levels-explained"
      }}>{`PAdES-LTA standard`}</a>{`.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Getting Started`}</strong>{`. If you are not yet familiar with GraphQL or have not yet registed a Criipto Signatures application please follow our `}<a parentName="li" {...{
          "href": "/signatures/getting-started/register-application"
        }}>{`Getting Started guide`}</a></li>
      <li parentName="ul"><strong parentName="li">{`GraphQL`}</strong>{`. The Criipto Signature API is built with GraphQL enabling you to build a unique workflow matching your exact needs, have a look at our `}<a parentName="li" {...{
          "href": "/signatures/graphql/examples"
        }}>{`GraphQL Examples`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      